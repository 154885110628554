const targetOrigin = "https://adminmaps.visitsofia.bg/"

export default {
    setIframeHeight: () => {
        const c: any = document.querySelector('.containers');
        window.parent.postMessage({iframeHeight: c.scrollHeight}, targetOrigin);
    },
    setDirectionTarget: (latLon: any) => {
        window.parent.postMessage({directionTarget: latLon}, targetOrigin);
    },
    updateCss: () => {
        const style: any = {
            '--object-item-height': '90px',
            '--main': '#898EBA',
            '--main-active': '#0F0572',
            '--box-space': '15px',
        }
        return style;
    }
}
